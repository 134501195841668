/*
 * @Author: 陈剑伟
 * @Date: 2020-05-19 14:10:56
 * @LastEditTime: 2020-06-15 16:00:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\course.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.searchValue = ''
      this.signUpStartTimeBegins = []
      this.signUpEndTimeBegins = []
      this.signUpStartTimeBegin = ''
      this.signUpStartTimeEnd = ''
      this.signUpEndTimeBegin = ''
      this.signUpEndTimeEnd = ''
      this.category = ''
      this.publishStatus = ''
      this.top = ''
      this.rules = ''
      this.checkFlag = ''
    }
  }
}
export default searchFrom

<template>
  <div class="dialog-container weekBox">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="700px"
    >
      <el-tabs class="taps-card" v-model="form.status">
        <el-tab-pane
          :label="`${isEdit ? '修改赛事' : '新增赛事'}`"
          name="first"
          style="padding-right: 10px"
        >
          <el-form ref="form" :model="form" label-width="120px" :rules="rules">
            <el-row>
              <el-col :span="12">
                <el-form-item label="赛事名称" prop="matchName">
                  <el-input
                    clearable
                    placeholder="最多输入20字"
                    v-model="form.matchName"
                    :maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="赛事主题" prop="matchThemeCode">
                  <el-select
                    v-model="form.matchThemeCode"
                    clearable=""
                    style="width: 100%"
                    @change="matchThemeCodeChange"
                  >
                    <el-option
                      v-for="item in matchThemeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="赛事制度" prop="rules">
                  <el-select
                    v-model="form.rules"
                    clearable=""
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in DROPDOWNBOX.VENUE_MATCH_RULES"
                      :key="`${item.label}_${index}`"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="form.rules === 'team'">
                <el-form-item label="参赛团队上限" prop="maxTeam">
                  <el-input clearable v-model="form.maxTeam" type="number">
                    <span slot="suffix">个</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="form.rules === 'team'">
                <el-form-item label="团队人数上限" prop="maxPersonTeam">
                  <el-input
                    clearable
                    v-model="form.maxPersonTeam"
                    type="number"
                  >
                    <span slot="suffix">人</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="form.rules === 'team'">
                <el-form-item label="参赛人数要求" prop="personRequired">
                  <el-input
                    clearable
                    v-model="form.personRequired"
                    type="number"
                  >
                    <span slot="suffix">人</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-else>
                <el-form-item label="参加人数上限" prop="maxQty">
                  <el-input clearable v-model="form.maxQty" type="number">
                    <span slot="suffix">人</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="报名开始时间" prop="signUpStartTime">
                  <el-date-picker
                    v-model="form.signUpStartTime"
                    style="width: 100%"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    :picker-options="startTimeOptions"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="报名截止时间"
                  prop="signUpEndTime"
                  :rules="[
                    {
                      type: 'email',
                      validator: this.validateEndTime,
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-date-picker
                    style="width: 100%"
                    :picker-options="endTimeOptions"
                    v-model="form.signUpEndTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="参加费用" prop="price">
                  <el-input clearable type="number" @blur="() => this.form.price = Number(this.form.price).toFixed(2)" v-model="form.price" >
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="12">
                <el-form-item label="赛事类型" prop="category">
                  <el-select v-model="form.category" style="width: 100%">
                    <el-option
                      v-for="item in sportList"
                      :key="item.sportsProjectCode"
                      :label="item.sportsProjectName"
                      :value="item.sportsProjectCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="赛事类型" prop="category">
                  <el-select v-model="form.category" style="width:100%">
                    <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_MATCH_CATEGORY" :key="`${item.label}_${index}`" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="比赛时间" prop="eventStartTime">
                  <el-date-picker
                    style="width: 100%"
                    v-model="form.eventStartTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    :picker-options="eventStartTimeOptions"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  class="red-require"
                  label="启用审核"
                  prop="checkFlag"
                >
                  <el-switch v-model="form.checkFlag"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  class="red-require"
                  label="启用邀请"
                  prop="inviteFlag"
                >
                  <el-switch v-model="form.inviteFlag"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="14">
                <el-form-item label="赛事封面图片" prop="picturePath">
                  <el-upload
                    class="upload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    name="file"
                    accept=".png,.jpg"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                  >
                    <img
                      v-if="form.pictureUrl"
                      class="image-upload"
                      :src="form.pictureUrl"
                      alt=""
                      srcset=""
                      width="78"
                      height="78"
                    />
                    <img
                      v-else
                      class="image-upload"
                      src="../../../../assets/images/upload_image.png"
                      alt=""
                      srcset=""
                    />
                    <div class="upload-images-tip">
                      图片尺寸不小于375*170px，图片格式png/jpg
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="票务信息"
                  prop="ticketIds"
                  label-width="120px"
                >
                  <el-select
                    v-model="form.ticketIds"
                    multiple
                    clearable=""
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in ticketList"
                      :key="index"
                      :label="item.ticketInfo"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item label="组别" prop="zubie">
                  <el-input
                    clearable
                    placeholder="请输入组别关键字，比如半马"
                    v-model="form.zubie"
                    :maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="group-btn">
                <el-button type="primary" size="mini" @click="onGroupAdd"
                  >新增</el-button
                >
              </el-col>
            </el-row>
            <el-row v-if="form.groups.length > 0">
              <el-col :span="24">
                <el-form-item label="" prop="zubie">
                  <el-tag
                    v-for="(item, index) in form.groups"
                    :key="index"
                    closable
                    @close="onTagClose(index)"
                    >{{ item.name }}</el-tag
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="价格"
                  prop="groups"
                  :rules="[
                    {
                      required: true,
                      validator: this.validatorPriceList,
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-table
                    :data="form.groups"
                    size="mini"
                    style="width: 100%"
                    max-height="200"
                    border=""
                  >
                    <el-table-column
                      prop="name"
                      align="center"
                      min-width="120"
                      show-overflow-tooltip
                      label="组别"
                    >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="groupTypeCode"
                      show-overflow-tooltip
                      min-width="150px"
                      label="组别类型"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.groupTypeCode"
                          clearable=""
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in matchTypeList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="price"
                      show-overflow-tooltip
                      min-width="120px"
                      label="价格"
                    >
                      <template slot-scope="scope">
                        <el-input
                          type="number"
                          clearable
                          @change="
                            tablePriceInput(scope.row.price, scope.row.index)
                          "
                          v-model="scope.row.price"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop=""
                      min-width="120px"
                      label="图片"
                    >
                      <template slot-scope="scope">
                        <!-- <img v-if="scope.row.image" src="scope.row.tupian" alt="" srcset=""> -->
                        <!-- <el-button size="small" round >上传</el-button> -->
                        <el-upload
                          class="upload"
                          :show-file-list="false"
                          :with-credentials="true"
                          :action="apis.ImageUpload"
                          :httpRequest="uploadHttpDefault"
                          name="file"
                          accept=".png,.jpg"
                          :on-success="uploadSuccessTable"
                          :on-error="uploadErrorTable"
                        >
                          <div class="tupian-wrapper">
                            <img
                              v-if="scope.row.picturePathUrl"
                              class="tupian-image-upload"
                              :src="scope.row.picturePathUrl"
                              alt=""
                              srcset=""
                              width="60"
                              height="60"
                            />
                            <div class="tupian-content">
                              <div class="upload-tupian-tip">尺寸100*100</div>
                              <el-button
                                size="small"
                                type="primary"
                                @click="onTableUpload(scope.row.index)"
                                >上传</el-button
                              >
                            </div>
                          </div>
                        </el-upload>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="售价" prop="price">
                  <el-input disabled type="number" v-model="form.price">
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="14" class="price-text">
                如果上面有多个价格，则这里默认带最低价格并且不允许修改
              </el-col> -->
            </el-row>
            <!-- <el-row>
              <el-col :span="12">
                <el-form-item label="原价" prop="yuanjia">
                  <el-input placeholder="正数，长度小于10" maxlength="10" clearable type="number" @blur="() => this.form.yuanjia = Number(this.form.yuanjia).toFixed(2)" v-model="form.yuanjia" >
                    <span slot="suffix">元</span>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="24">
                <el-form-item label="比赛简介" prop="introduction">
                  <el-input
                    clearable
                    placeholder="请用一句话简短说明(最多输入50个字)..."
                    type="textarea"
                    show-word-limit
                    :maxlength="50"
                    :rows="4"
                    v-model="form.introduction"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="赛事详情" name="second">
          <el-form ref="form2" :model="form" label-width="120px" :rules="rules">
            <el-col :span="24"
              ><div class="course-text">赛事详情介绍（图片）</div></el-col
            >
            <el-col :span="24">
              <el-form-item prop="detail" label-width="0">
                <div class="edit_container">
                  <!-- <quill-editor v-model="form.detail" ref="myQuillEditor" class="editer" :options="editorOption" @ready="onEditorReady($event)" @change="onEditChange">
                  </quill-editor> -->
                  <quill-edit
                    :detail="form.detail"
                    @change="onEditChange"
                    :qnLocation="apis.ImageUpload"
                  >
                  </quill-edit>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24"
              ><div class="course-text">报名需要信息</div></el-col
            >
            <el-col :span="24">
              <el-form-item label-width="0" prop="registerCodes">
                <el-checkbox-group
                  v-model="form.registerCodes"
                  size="mini"
                  class="course-checkbox"
                  @change="onApply"
                >
                  <el-checkbox
                    disabled
                    style="width: 209px"
                    v-for="item in DROPDOWNBOX.VENUE_APPLY_INFO"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button
          class="footer-confirm"
          v-if="form.status === 'first'"
          @click="nextButton"
          >下一步</el-button
        >
        <el-button
          class="footer-confirm"
          v-if="form.status === 'second'"
          @click="onPreview"
          >预览</el-button
        >
        <el-button
          class="footer-confirm"
          v-if="form.status === 'second'"
          @click="sureEvent"
          >{{ $t("sure") }}</el-button
        >
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
    <!-- 图片预览 -->
    <preview-image
      ref="previewImage"
      :show="previewImage.visible"
      @closeDialog="
        () => {
          this.previewImage.visible = false;
        }
      "
    ></preview-image>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import mixin from "@/mixins/dialogMixin";
import twoStepDialogMixin from "@/mixins/twoStepDialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import previewImage from "../component/preview.vue";
import quillEdit from "@/components/quillEdit.vue";

export default {
  components: {
    // quillEditor,
    previewImage,
    quillEdit,
  },
  mixins: [mixin, twoStepDialogMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    matchTypeList: {
      required: true,
      type: Array,
    },
  },
  /* eslint-disable */
  created() {
    console.log("created -> DROPDOWNBOX", this.DROPDOWNBOX.VENUE_APPLY_INFO);
  },
  data() {
    return {
      flag: false,
      tableIndex: null,
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      typeList: [],
      ticketList: [],
      matchThemeList: [],
      apis,
      showPic: true,
      coachList: [], //教练集合
      applyInfoList: [], //报名列表集合
      editorOption: {
        placeholder: "请输入...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["image", "formula"], //去除video即可
          ],
        },
      },
      previewImage: {
        visible: false,
        src: "",
      },
      // 结束时间不能大于开始时间
      startTimeOptions: {
        disabledDate: (time) => {
          if (this.form.signUpEndTime) {
            return time.getTime() > new Date(this.form.signUpEndTime).getTime(); //|| time.getTime() < Date.now() - 8.64e7;
          } else {
            //还没有选择结束时间的时候，让他只能选择今天之后的时间包括今天
            // 这里需求改了，允许选择今天之前的时间
            // return time.getTime() < Date.now() - 8.64e7
          }
        },
      },
      endTimeOptions: {
        disabledDate: (time) => {
          if (this.form.signUpStartTime) {
            return (
              time.getTime() <=
              new Date(this.form.signUpStartTime).getTime() - 8.64e7
            );
          } else {
            //还没有选择开始时间的时候，让他只能选择今天之后的时间包括今天
            // 这里需求改了，允许选择今天之前的时间
            // return time.getTime() < Date.now() - 8.64e7
          }
        },
      },
      eventStartTimeOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.form.signUpEndTime).getTime();
        },
      },
      sportList: [],
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN"]),
  },
  methods: {
    getSportList() {
      this.$http
        .get(`${apis.getSportsNewProject}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    matchThemeCodeChange(val) {
      if (val) {
        this.$http
          .get(`${apis.matchThemeGetApplyInfo}?code=${val}`)
          .then((res) => {
            if (res.data.code === 0) {
              this.form.registerCodes = res.data.data;
            }
          });
      }
    },
    getTicketList() {
      const venueId = JSON.parse(sessionStorage.getItem("vuex")).venueId;
      this.$http
        .post(`${apis.getTicketList}`, { venueId: venueId })
        .then((res) => {
          if (res.data.code === 0) {
            this.ticketList = res.data.rows;
          }
        });
      this.$http.get(apis.matchThemeList).then((res) => {
        if (res.data.code === 0) {
          this.matchThemeList = res.data.data;
        }
      });
    },
    validatorPriceList(rule, value, callback) {
      const priceList = this.form.groups.filter((item) => {
        return item.price;
      });
      console.log(
        "tablePriceInput -> this.form.price",
        priceList,
        this.form.groups.length
      );
      const Low0List = priceList && priceList.filter((item) => item.price <= 0);
      if (this.form.groups.length > priceList.length) {
        callback([new Error("请填写列表价格")]);
      } else if (Low0List.length > 0) {
        callback([new Error("价格需大于0")]);
      } else {
        callback();
      }
    },
    tablePriceInput(value, index) {
      let name = "";
      if (!value) {
        name = "请输入价格";
      } else {
        if (Number(value) === 0) {
          name = "价格不能为0";
        } else if (Number(value) < 0) {
          name = "价格不能为负数";
        }
      }
      if (name) {
        this.$message.error(name);
        return false;
      }
      let priceList = this.form.groups.map((item) => {
        return Number(item.price);
      });
      this.form.price = Math.min(...priceList);
      this.$refs.form.validateField("groups");
      console.log(
        "tablePriceInput -> this.form.price",
        priceList,
        this.form.price
      );
    },
    onTagClose(index) {
      console.log(index);
      this.form.groups.splice(index, 1);
    },
    onGroupAdd(value) {
      if (this.form.zubie) {
        if (
          this.form.groups.filter((item) => item.name === this.form.zubie)
            .length !== 0
        ) {
          this.$message.error("添加组别类型重复！");
          return false;
        }
        let index = this.form.groups.length;
        this.form.groups.push({
          index: index,
          name: this.form.zubie,
          price: "",
          picturePath: "",
          picturePathUrl: "",
        });
      } else {
        this.$message.error("请先填写组别");
      }
    },
    validateEndTime(rule, value, callback) {
      const endTime = new Date(this.form.signUpEndTime).getTime();
      const beginTime = new Date(this.form.signUpStartTime).getTime();
      if (!value) {
        callback([new Error("请输入报名截止时间")]);
      } else {
        if (beginTime >= endTime) {
          callback([new Error("报名截止时间需大于报名开始时间")]);
        } else {
          callback();
        }
      }
    },
    onEditChange(value) {
      this.form.detail = value;
      this.$refs.form.validateField("detail");
    },
    onApply(value) {
      console.log("onApply -> value", value);
    },
    onPickerChange(value) {
      this.form.startTime = value[0];
      this.form.deadLine = value[1];
    },
    /**
     * @function 文本编辑器
     */
    onEditorReady(editor) {
      console.log(editor);
    },
    onPreview() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.previewImage.visible = true;
          this.$nextTick(() => {
            this.$refs["previewImage"].getPreviewData(this.form);
          });
        } else {
          this.changeTap();
        }
      });
    },
    /**
     * 获取教练集合
     */
    getCoachInfo() {
      this.$http
        .post(apis.coachInfoList, {
          pageNum: 1,
          pageSize: 100000,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.coachList = res.data.rows;
          }
        });
    },
    removeImage(index) {
      this.form.filesPicturesPath.splice(index, 1);
      this.form.files.splice(index, 1);
    },
    uploadSuccess(res) {
      console.log(res);
      this.form.pictureUrl = res.data.url;
      this.form.picturePath = res.data.path;
      this.$refs.form.validateField("picturePath");
    },
    onTableUpload(index) {
      console.log("onTableUpload -> index", index);
      this.tableIndex = index;
    },
    uploadSuccessTable(res) {
      console.log("uploadSuccessTable", res, this.form.groups, this.tableIndex);
      this.form.groups[this.tableIndex].picturePath = res.data.path;
      this.form.groups[this.tableIndex].picturePathUrl = res.data.url;
      // this.$refs.form.validateField("picturePath");
    },
    uploadErrorTable(res, index) {
      console.log(res);
    },
    uploadError(res) {
      console.log(res);
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    onSave() {
      // 这里写业务需求
      // this.$refs.form.validate((valid) => {
      // if (valid) {
      if (this.flag) {
        return;
      }
      this.flag = true;
      if (this.isEdit) {
        this.$http
          .put(apis.matchInfo, {
            ...this.form,
            checkFlag: this.form.checkFlag ? "Y" : "N",
            inviteFlag: this.form.inviteFlag ? "Y" : "N",
            // ticketIds: this.form.ticketIds && this.form.ticketIds.toString()
          })
          .then((res) => {
            console.log(this.form);
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.flag = false;
              this.closeDialog();
            } else {
              this.flag = false;
            }
          });
      } else {
        this.$http
          .post(apis.matchInfo, {
            ...this.form,
            checkFlag: this.form.checkFlag ? "Y" : "N",
            inviteFlag: this.form.inviteFlag ? "Y" : "N",
            // ticketIds: this.form.ticketIds && this.form.ticketIds.toString()
          })
          .then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.flag = false;
              this.closeDialog();
            } else {
              this.flag = false;
            }
          });
      }
      // } else {
      // this.changeTap();
      // }
      // })
      // console.log(`我执行了点确定的业务需求`)
    },
    // 切换tab类型 保存时，如果没有填的要切换tab
    changeTap() {
      if (!this.form.registerCodes || !this.form.detail) {
        this.form.status = "second";
      } else {
        this.form.status = "first";
      }
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.getTicketList();
      this.$http.get(`${apis.matchInfo}/${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...this.form,
            ...res.data.data,
            // ticketIds: res.data.data.ticketIds && res.data.data.ticketIds.split(','),
            price: res.data.data.price.toFixed(2),
            inviteFlag: res.data.data.inviteFlag === "Y" ? true : false,
            checkFlag: res.data.data.checkFlag === "Y" ? true : false,
          };
          this.form.groups = this.form.groups.map((item, index) => {
            return {
              index: index,
              ...item,
            };
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
/deep/ .el-form-item.red-require > .el-form-item__label:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.el-tag {
  margin-right: 10px;
}
.tupian-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .tupian-content {
    margin-left: 10px;
  }
  .tupian-image-upload {
    color: #a8a8a8;
  }
}
.group-btn {
  line-height: 40px;
  padding-left: 10px;
}
.price-text {
  padding-left: 10px;
}
.course-text {
  margin-bottom: 20px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
  position: absolute;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .ql-editor {
    .ql-editor {
      height: 188px !important;
    }
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
  .course-checkbox {
    .el-checkbox {
      width: 209px !important;
    }
  }
}
</style>

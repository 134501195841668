<!--
 * @Author: genwai
 * @Date: 2020-05-21 14:07:33
 * @LastEditTime: 2020-10-08 11:31:32
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\component\preview.vue
-->
<template>
  <div class="image-preview-wrapper">
     <el-dialog
      :visible.sync="show"
      :before-close="closeDialog"
      center
      :show-close="false"
      class="image-preview-applet-content"
      :close-on-click-modal="false"
      width="100%">
      <div class="out-wrapper">
        <div class="match-items-wrapper" >
          <div class="match-item" >
            <!-- <image class="images" :src="form.pictureUrl" mode=""></image> -->
            <img class="images" :src="form.pictureUrl" alt="" srcset="">
            <div class="right">
              <div class="top">{{ form.matchName }}</div>
              <div class="item-center">中山路步行街100号</div>
              <div class="btn">报名</div>
              <!-- <div class="btn-over" v-else>已结束</div> -->
            </div>
          </div>
        </div>
        <div class="img-wrapper">
          <div class="applet-wrapper">
            <div class="applet-head">
              <img src="@/assets/images/pre_banner.png" alt="" srcset="">
              <div class="name">赛事详情</div>
            </div>
            <div class="applet-content">
              <img class="image" :src="form.pictureUrl" alt="">
              <img class="collection" src="@/assets/images/pre_collection.png" alt="">
              <div class="applet-contaner">
                <div class="course-infor">
                  <div class="infor-top">
                    <div class="left">
                      <div class="name">{{form.matchName}}</div>
                      <div class="price"><span class="price">￥{{form.price}}</span></div>
                    </div>
                    <div class="right">
                      <img class="images" src="@/assets/images/pre_share.png" alt="">
                      <div class="call">分享</div>
                    </div>
                  </div>
                  <div class="info-bottom ">
                    <img class="images" src="@/assets/images/pre_compe.png" alt="">
                    <div class="right"> 比赛时间：{{form.eventStartTime}}</div>
                  </div>
                  <div class="info-bottom applay">
                    <img class="images" src="@/assets/images/pre_time.png" alt="">
                    <div class="right"> 报名截止时间：{{form.signUpEndTime}}</div>
                  </div>
                </div>
                <div class="course-content">
                  <!-- <div class="title">课程介绍</div> -->
                  <div class="content" v-html="form.detail"></div>
                </div>
              </div>
            </div>
            <div class="btn-wrapper">
              <div class="left">
                <img class="time-images" src="@/assets/images/pre_timer_b.png" alt="" srcset="">
                <div class="left-wrapper">
                  <div class="top">距离报名结束还有</div>
                  <div class="bottom">{{this.getTimeDurationDetail(this.getNowFormatDate(),form.signUpEndTime)}}</div>
                </div>
              </div>
              <div class="right">立即报名</div>
            </div>
          </div>
          <div class="preview-over" @click="closeDialog">结束预览</div>
        </div>
      </div>
     </el-dialog>
  </div>
</template>

<script>
// import apis from '@/apis'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from '../dialog/addDialogClass.js'

export default {
  mixins: [mixin],
  //部件
  components: {},
  //静态
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    src: {
      type: String,
      default: "",
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'),
    }
  },
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {},
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getPreviewData(preData) {
      console.log("getDataQuery -> data111111", preData)
      this.form = {
        ...this.form,
        ...preData,
        price: Number(preData.price).toFixed(2),
      }
    },
    /* eslint-disable */
    getNowFormatDate() {
      const date = new Date();
			const seperator1 = '-';
			const seperator2 = ':';
      const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
			const strDate = date.getDate() < 10? "0" + date.getDate() : date.getDate();
			const currentdate = date.getFullYear() + seperator1  + month  + seperator1  + strDate + " "  + date.getHours()  + seperator2  + date.getMinutes()	+ seperator2 + date.getSeconds();
			return currentdate;
    },
    /**
     * @function 计算两个时间的差别天数包括时分秒
     * @param {String}} startDate 开始时间
     * @param {String} endDate 结束时间
     */
    getTimeDurationDetail(startDate, endDate) {
      const date3 = new Date(endDate).getTime() - new Date(startDate).getTime() // 时间差的毫秒数
      // 计算出相差天数
      const days = Math.floor(date3 / (24 * 3600 * 1000))
      // 计算出小时数

      const leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
      const hours = Math.floor(leave1 / (3600 * 1000))
      // 计算相差分钟数
      const leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
      const minutes = Math.floor(leave2 / (60 * 1000))
      // 计算相差秒数
      const leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
      const seconds = Math.round(leave3 / 1000)
      return `${days}天${hours}时${minutes}分${seconds}秒`
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>
<style>
.content img {
  width: 100%;
}
</style>
<style lang="less" scoped>
/deep/.el-dialog{
  box-shadow: none;
}
.image-preview-applet-content {
  .out-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .match-items-wrapper {
    width: 375px;
    margin-right: 20px;
		padding: 10px 15px 0;
		.match-item {
			display: flex;
			flex-direction: row;
			background: #fff;
			padding: 8px;
			margin-bottom: 10px;
			.images {
				width: 138px;
				height: 90px;
				margin-right: 10px;
				flex-shrink: 0;
			}
			.right {
				display: flex;
				height: 90px;
				flex-wrap: wrap;
				.top {
					font-weight: bold;
					color: rgba(23, 32, 66, 1);
					line-height: 19px;
					font-size: 13px;
					width: 100%;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				.item-center {
					font-size: 12px;
					font-weight: 400;
					color: rgba(153, 153, 153, 1);
					margin-bottom: 19px;
					width: 100%;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				.btn {
					width: 71px;
					height: 25px;
					line-height: 25px;
					font-size: 12px;
					text-align: center;
					color: rgba(61, 100, 236, 1);
					border-radius: 12px;
					border: 1px solid rgba(61, 100, 236, 1);
					align-self: flex-end;
				}
				.btn-over {
					width: 71px;
					height: 25px;
					line-height: 25px;
					font-size: 12px;
					text-align: center;
					color: rgba(153, 153, 153, 1);
					border-radius: 12px;
					border: 1px solid rgba(153, 153, 153, 1);
				}
			}
		}
	}
  .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .preview-over {
    cursor: pointer;
    height:49px;
    width: 375px;
    line-height: 49px;
    background:rgba(61,100,236,1);
    border-radius:25px;
    font-weight:400;
    color:rgba(255,255,255,1);
    font-size:14px;
    text-align: center;
    margin-top: 20px;
  }
  .applet-wrapper {
    width: 375px;
    min-height: 667px;
    background: #F7F8FA;
    position: relative;
  }
  .applet-head {
    background: #3D64EC;
    height: 64px !important;
    height: 100%;
    position: relative;
    .name {
      font-size:17px;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:24px;
      position: absolute;
      left: 33px;
      bottom: 11px;
    }
  }
  .applet-content {
    background: #F7F8FA;
    padding-bottom: 20px;
    position: relative;
    .collection {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    .applet-contaner {
      margin-top: -32px;
      position: relative;
    }
    .course-infor {
      background:rgba(255,255,255,1);
      box-shadow:0px 8px 12px 0px rgba(81,113,225,0.08);
      border-radius:10px;
      margin: 0 15px;
      padding: 12px 10px;
      .infor-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 11px;
        .left {
          .name {
            font-weight:500;
            color:rgba(23,32,66,1);
            line-height:22px;
            font-size:15px;
            margin-bottom: 5px;
          }
          .price {
            font-size:10px;
            font-weight:400;
            color:rgba(223,33,17,1);
            line-height:14px;
          }
          .ori-price {
            font-size:10px;
            font-weight:400;
            color:rgba(153,153,153,1);
            line-height:14px;
            text-decoration: line-through;
            margin-left: 3px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          width: 92px;
          border-left: 1px solid #DCDCDC;
          align-items: center;
          .images {
            width: 16px;
            height: 16px;
          }
          .call {
            color:rgba(61,100,236,1);
            font-size:10px;
            margin-top: 2px;
          }
        }
      }
    }
    .info-center {
      background:rgba(249,247,241,1);
      color:rgba(179,136,67,1);
      border-radius:4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      font-weight:400;
      margin-top: 8px;
      margin-bottom: 8px;
      .left {
        font-size:15px;
      }
      .right {
        font-size:12px;
      }
    }
    .info-bottom {
      color:rgba(82,82,82,1);
      font-size:12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .images {
        width: 10px;
        height: 12px;
      }
      .right {
        margin-left: 4px;
        color:#525252;
      }
    }
    .applay {
      margin-top: 4px;
    }
    .image {
      height: 170px;
      width: 100%;
    }
  }
  .btn-wrapper {
    display: flex;
    flex-direction: row;
    height: 49px;
    position: absolute;
    bottom: 0;
    width: 100%;
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      .time-images {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
      .left-wrapper {
        .top {
          color:rgba(153,153,153,1);
          line-height:14px;
          font-size:10px;
          font-weight:400;
        }
        .bottom {
          font-weight:400;
          color:rgba(61,100,236,1);
          line-height:17px;
          height:17px;
          font-size: 12px;
          margin-top: 3px;
        }
      }
    }
    .right {
      width: 163px;
      line-height: 49px;
      text-align: center;
      font-weight:400;
      color:rgba(255,255,255,1);
      font-size:14px;
      background: #3D64EC;
    }
  }
  .course-content {
    min-height: 250px;
    margin: 20px 15px;
  }
}
</style>
<style lang="less">
.image-preview-applet-content {
  .el-dialog {
    background: transparent !important;
  }
  .el-dialog__header {
    border-bottom: 0 !important;
  }
}

</style>

/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-07-04 14:33:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePrice = (rule, value, callback) => {
    if (!value) {
      callback([new Error('请输入参加费用')]);
    } else {
        if (Number(value) === 0) {
            callback([new Error('参加费用不能为0')]);
        } else if (Number(value) < 0) {
          callback([new Error('参加费用不能为负数')]);
        } else {
            callback();
        }
   }
  }
  constructor(type) {
    if (type === 'form') {
      this.status = 'first'
      this.matchName = ''
      this.detail = ''
      this.category = ''
      this.registerCodes = ['name', 'mobile']
      this.introduction = ''
      this.price = ''
      this.maxQty = ''
      this.personRequired = ''
      this.signUpStartTime = ''
      this.signUpEndTime = ''
      this.eventStartTime = ''
      this.picturePath = ''
      this.pictureUrl = ''
      this.files = []
      this.groups = []
      this.zubie = ''
      this.ticketIds = []
      this.maxPersonTeam = ''
      this.maxTeam = ''
      this.rules = ''
      this.matchThemeCode =''
      // 无需传给后端
      this.filesPicturesPath = []
    } else if (type === 'rule') {
      this.shoujia = [
        {
          required: true,
          message: '请输价格',
          trigger: 'change',
        },
      ]
      this.groups  = [
        {
          required: true,
          message: '请输入价格',
          trigger: 'change',
        },
      ]
      this.registerCodes = [
        {
          required: true,
          message: '请选择报名信息',
          trigger: 'change',
        },
      ]
      this.matchName = [
        {
          required: true,
          message: '请输入赛事名称',
          trigger: 'change',
        },
      ]
      this.category = [
        {
          required: true,
          message: '请选择赛事类型',
          trigger: 'change',
        },
      ]
      this.matchThemeCode = [
        {
          required: true,
          message: '请选择赛事主题',
          trigger: 'change',
        },
      ]
      this.duration = [
        {
          required: true,
          message: '请输入时长',
          trigger: 'change',
        },
      ]
      this.introduction = [
        {
          required: true,
          message: '请输入赛事简介',
          trigger: 'change',
        },
      ]
      this.detail = [
        {
          required: true,
          message: '请输入赛事详情介绍',
          trigger: 'change',
        },
      ]
      this.price = [
        {
          required: true,
          validator: this.validatePrice,
          trigger: 'change',
        },
      ]
      this.maxQty = [
        {
          required: true,
          message: '请输入赛事人数上限',
          trigger: 'change',
        },
      ]
      this.courseQty = [
        {
          required: true,
          message: '请输入赛事节数',
          trigger: 'change',
        },
      ]
      this.coach = [
        {
          required: true,
          message: '请输入教练',
          trigger: 'change',
        },
      ]
      this.signUpStartTime = [
        {
          required: true,
          message: '请输入选择报名开始时间',
          trigger: 'change',
        },
      ]
      this.signUpEndTime = [
        {
          required: true,
          message: '请输入选择报名截止时间',
          trigger: 'change',
        },
      ]
      this.eventStartTime = [
        {
          required: true,
          message: '请输入选择赛事进行时间',
          trigger: 'change',
        },
      ]
      this.picturePath = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
      this.rules = [
        {
          required: true,
          message: '请选择赛事制度',
          trigger: 'change',
        },
      ]
      this.maxTeam = [
        {
          required: true,
          message: '参赛团队上限',
          trigger: 'change',
        },
      ]
      this.maxPersonTeam = [
        {
          required: true,
          message: '团队人数上限',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
